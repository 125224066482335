$height: 170px;

.orderable-list {
  width: 100%;
  height: max-content;
  max-width: 600px;
  padding: 0;
  padding-bottom: 100px;
  margin: 0;
  position: relative;
  list-style: none;

  li {
    margin-bottom: 10px;
    width: 100%;
    height: $height;
    background: white;
    display: flex;
    cursor: grab;
    overflow: hidden;
    position: relative;
    &::after,
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #ffaa00;
      position: absolute;
      left: 70%;
      transform: translate(-50%, -50%);
    }
    &::after {
      top: 100%;
    }
    &::before {
      top: 0;
    }
  }

  .logo-container {
    width: 70%;
    border-right: 1px dashed #999;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 90%;
      width: 90%;
      border-radius: 10px;
      overflow: hidden;
      object-fit: contain;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
    }
  }

  .mock-ticket {
    width: 30%;
    padding: 10px;
    .boarding-section,
    .flight-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flight-section {
      padding: 10px 0;
      margin: 10px 0;
      border-top: 1px dashed #ffaa00;
      border-bottom: 1px dashed #ffaa00;
    }
    .boarding-section {
    }

    .bold {
      font-weight: bold;
    }
    .secondary {
      font-size: 0.7em;
    }
  }

  @media (max-width: 600px) {
    li {
      &::after,
      &::before {
        left: 50%;
      }
    }
    .logo-container {
      width: 50%;
    }
    .mock-ticket {
      width: 50%;
    }
  }
}
