.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  max-width: 90%;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .close {
      border: none;
      background-color: #fff;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      transition: background-color 0.25s;
      &:hover {
        background-color: #ffaa003e;
      }
    }
  }
}
