.app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffaa00;
  padding: 16px;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  .fixed-btns {
    position: fixed;
    bottom: 16px;
    right: 24px;
    display: flex;
    gap: 10px;
  }
  .copy-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 10px;
    // border: 1px solid black;
    background-color: #ffaa00;
    border-radius: 8px;
    .copy-text {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .copy-btn {
      border-radius: 8px;
      padding: 8px 16px;
      border: none;
      background-color: white;
      cursor: pointer;
    }
  }
}
